// jobOffers.ts
import type { JobsOffersQuery } from "~/models/Api";
import HttpFactory from "~/repository/factory";

class JobsModule extends HttpFactory {
  async list({ page, q, c, cp, nb }: JobsOffersQuery = {}) {
    const config = useRuntimeConfig();
    const jobOffersUrl = config.public.jobSearchOffers;
    if (process.client) {
      return await $fetch(jobOffersUrl, {
        params: { page, q, c, cp, nb },
      });
    }

    return this.call({
      method: "GET",
      url: jobOffersUrl,
      fetchOptions: {
        params: { page, q, c, cp, nb },
      },
    });
  }
}

export default JobsModule;
