import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  setAnalyticsCollectionEnabled,
  logEvent,
} from "firebase/analytics";

interface AnalyticsEventParams {
  [key: string]: any;
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  const firebaseConfig = {
    apiKey: config.public.firebaseApiKey,
    authDomain: config.public.firebaseAuthDomain,
    databaseURL: config.public.firebaseDbUrl,
    projectId: config.public.firebaseProjectId,
    storageBucket: config.public.firebaseStorageBucket,
    messagingSenderId: config.public.firebaseMessagingSenderId,
    appId: config.public.firebaseAppId,
    measurementId: config.public.firebaseMeasurementId,
  };

  let analytics: any = null;

  // Initialize Firebase
  if (shouldLoadTrackingTools()) {
    const app = initializeApp(firebaseConfig);
    analytics = getAnalytics(app);
    nuxtApp.provide("firebase", app);
    nuxtApp.provide("analytics", analytics);
    setAnalyticsCollectionEnabled(analytics, true);
  }

  // Send event to GA4
  const firebaseSendEvent = (eventName: string, eventParams?: AnalyticsEventParams) => {
    if (analytics) {
      try {
        logEvent(analytics, eventName, eventParams);
      } catch (error) {
        throw new Error(`Error tracking event: ${error}`);
      }
    }
  };

  nuxtApp.provide("firebaseSendEvent", firebaseSendEvent);
});
