export const applications = {
  jobs: "https://jobs.extracadabra.com/",
  recrutement: "https://recrutement.extracadabra.com/",
  playStore: {
    candidates:
      "https://play.google.com/store/apps/details?id=com.extracadabra.android.extra",
    etab: "https://play.google.com/store/apps/details?id=com.extracadabra.android.etab",
    logo: "/img/logo-playstore.webp",
  },
  appleStore: {
    candidates: "https://apps.apple.com/fr/app/extracadabra-etablissement/id1056999788",
    etab: "https://apps.apple.com/fr/app/extracadabra-etablissement/id1056907780",
    logo: "/img/logo-appstore.webp",
  },
  qr: {
    candidates: "/img/candidats/qr-candidat.png",
    etab: "/img/recruteurs/qr-etab.png",
  },
};
