<script setup>
import appConfig from "~/app.config";
const route = useRoute();
const blogStore = useBlogStore();

const shouldExcludeCanonical =
  route.path.includes("/faq/candidats/") || route.path.includes("/faq/recruteurs/");

const showBanner = ref(false);

function bannerIsVisible() {
  const storedValue = localStorage.getItem("bannerVisible");
  if (storedValue === null) {
    localStorage.setItem("bannerVisible", true);
  }
  showBanner.value = storedValue === "true";
}

useHead(() => {
  const links = [];

  if (!shouldExcludeCanonical) {
    links.push({
      rel: "canonical",
      href: `https://www.extracadabra.com${route.path}`,
    });
  }
  return {
    link: links,
    meta: [
      {
        name: "robots",
        content: "index, follow",
      },
      {
        name: "og:url",
        content: `https://www.extracadabra.com${route.path}`,
      },
      {
        name: "og:site_name",
        content: "Extracadabra",
      },
      {
        name: "og:image",
        content:
          "https://static-cadabra.s3.eu-west-3.amazonaws.com/large_Presentation_Extracadabra_854bbec0c5.png",
      },
    ],
  };
});

onMounted(() => {
  bannerIsVisible();
  window.addEventListener("storage", (event) => {
    if (event.key === "bannerVisible") {
      bannerIsVisible();
    }
  });
});
</script>

<template>
  <main class="default-main">
    <slot name="temporary-banner-push" />

    <BannerExtracadabra class="banner-extracadabra" />
    <AppBanner v-if="showBanner" class="banner-app" />
    <HeaderNav class="navigation" :menu="appConfig.menu.nav" />

    <ProgressBar
      v-if="blogStore.progressBarIsActive"
      :progress="blogStore.progressReadingPercent"
    />

    <slot name="content-header" />

    <div class="default-layout container">
      <slot />
    </div>
    <slot name="full-size-bottom" />
    <slot name="content-footer" />
    <!-- Popups -->
    <ClientOnly>
      <Teleport to="#portal-popup">
        <PopupUsersCheckType />
      </Teleport>
    </ClientOnly>

    <FooterBig :footer-data="appConfig.footer" />
  </main>
</template>
<style lang="scss">
:root {
  --nav-position-top: 0;
}
.navigation {
  top: calc(var(--nav-position-top));
  transition: all ease-in-out 0.3s;
}

.temporary-banner-push.active + .navigation {
  --nav-position-top: 30px;
  top: calc(var(--nav-position-top)) !important;
}
.temporary-banner-push + .navigation {
  --nav-position-top: 30px;
  top: 0;
  transition: all ease-in-out 0.3s;
}
.temporary-banner-push.active + .navigation + .default-layout {
  --nav-position-top: 30px;
  margin-top: calc(var(--nav-position-top)) !important;
  transition: all ease-in-out 0.3s;
}

.banner + .navigation + .default-layout {
  margin-top: 0;
  transition: all ease-in-out 0.3s;
}
.default-main {
  padding-top: $menu-height;
}
.fullheight {
  height: calc(100vh - $menu-height);
}
.faq-layout {
  position: relative;
  &:before,
  &:after {
    content: "";
    display: block;
    z-index: -1;
    position: absolute;
  }
  &:before {
    width: 400px;
    height: 394px;
    background: url("/img/foire-question-habillage.png") center no-repeat;
    left: 0;
    top: 150px;
    display: none;
    @include mq(desktop) {
      display: block;
    }
  }
  &:after {
    width: 465px;
    height: 301px;
    background: url("/img/foire-question-habillage-mobile.png") center no-repeat;
    left: 0;
    bottom: 237px;
    @include mq(desktop) {
      width: 325px;
      height: 391px;
      display: block;
      background: url("/img/foire-question-habillage-2.png") center no-repeat;
      right: 0;
      top: 150px;
      bottom: auto;
      left: auto;
    }
  }
  .default-layout {
    padding: 0;
    @include mq(desktop) {
      padding: 0 $grid-padding;
    }
    .breadcrumb {
      padding-left: var(--showcase-size-100);
      @include mq(desktop) {
        padding-left: 0;
      }
    }
  }
}
.partenaires-layout,
.presse-layout {
  position: relative;
  &:before,
  &:after {
    content: "";
    display: block;
    z-index: -1;
    position: absolute;
  }
  &:before {
    width: 664px;
    height: 505px;
    background: url("/img/partenaires-habillage.png") center no-repeat;
    left: 0;
    top: 50px;
    display: none;
    @include mq(desktop) {
      display: block;
    }
  }
  &:after {
    width: 374px;
    height: 285px;
    background: url("/img/partenaires-habillage-mobile.png") center no-repeat;
    left: 0;
    bottom: 265px;
    @include mq(desktop) {
      width: 708px;
      height: 507px;
      display: block;
      background: url("/img/partenaires-habillage-2.png") center no-repeat;
      right: 0;
      top: 50px;
      bottom: auto;
      left: auto;
    }
  }
  .default-layout {
    padding: 0;
    @include mq(desktop) {
      padding: 0 $grid-padding;
    }
    &.container {
      position: static;
    }
    .breadcrumb {
      padding-left: var(--showcase-size-050);
      @include mq(desktop) {
        padding-left: 0;
      }
    }
  }
  &.internal {
    .default-layout {
      padding: 0 var(--showcase-size-125);
      @include mq(desktop) {
        padding: 0 $grid-padding;
      }
      .breadcrumb {
        padding-left: 0;
        @include mq(desktop) {
          padding-left: 0;
        }
      }
    }
  }
}
.default-layout {
  max-width: $grid-full-width;
  padding: 0 $grid-padding;
  margin: 0 auto;
  position: relative;
  height: 100%;
  width: $grid-full-width;

  .header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
  }
  .footer {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
  }
  @include mq(desktop) {
    max-width: $grid-medium-width;
    &.container {
      max-width: $grid-medium-width;
      margin: 0 auto;
    }
  }
  @include mq(desktop) {
    max-width: $grid-full-width;
    &.container {
      max-width: $grid-max-width;
      margin: 0 auto;
    }
  }
}
.home-layout {
  @include mq(desktop) {
    background: url("/img/bg-home-desktop.webp") left 122px no-repeat;
    background-size: contain;
  }
  @include mq(wide) {
    background-position: left top;
    background-size: contain;
  }
  .default-layout {
    padding: 0;

    &.container {
      @include mq(desktop) {
        max-width: $grid-max-width;
        position: inherit;
      }
    }
    .breadcrumb {
      padding-left: $grid-padding;
    }
  }
}
.fullwidth {
  .default-layout.container {
    padding: 0;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    height: 100%;
    width: 100%;
  }
}
</style>
