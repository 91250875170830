import type { $Fetch, FetchOptions } from "ofetch";
import { stringify } from "qs";

interface HttpFactoryCall {
  method: "GET" | "POST" | "PUT";
  url: string;
  data?: object;
  fetchOptions?: FetchOptions<"json">;
}

class HttpFactory {
  public $fetch: $Fetch;

  constructor(fetcher: $Fetch) {
    this.$fetch = fetcher;
  }

  async call<T>({ method, url, data, fetchOptions }: HttpFactoryCall): Promise<T> {
    const corsOptions: FetchOptions<"json"> = {
      credentials: "include",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    return await this.$fetch<T>(url, {
      method,
      body: data,
      ...corsOptions,
      ...fetchOptions,
      headers: {
        ...corsOptions.headers,
        ...(fetchOptions?.headers || {}),
      },
      onRequest({ options }) {
        if (options.params) {
          const convertedOptions = new URLSearchParams(stringify(options.params));
          options.params = Object.fromEntries(convertedOptions);
        }
      },
    });
  }
}

export default HttpFactory;
