import { routes } from "~/constants";
export const banner = {
  classic: true,
  text: "Envie de faire briller<br /> ton talent en Hôtellerie ?",
  cta: "en savoir plus",
  picto: "/img/cloche-hotel.png",
  altTitle: "Logo symbole cloche",
  url: `${routes.candidatUrl.index}/${routes.candidatUrl.lpHotellerie}`,
  utm: "banner-candidat-hotellerie",
};
